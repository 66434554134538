import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'
import convertLazyRouteObject from 'router/utils/convertLazyRouteObject'
import { LazyRouteObject } from 'router/utils/types'

function createLazyRouter(routes: LazyRouteObject[]) {
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
  const configuredRoutes = routes.map(convertLazyRouteObject)

  return sentryCreateBrowserRouter(configuredRoutes)
}

export default createLazyRouter
