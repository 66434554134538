import 'tailwindcss/tailwind.css'
import 'index.css'

import * as Sentry from '@sentry/react'
import App from 'app/components/App'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

//  =================== Sentry Setup =====================
Sentry.init({
  enabled: import.meta.env.VITE_NODE_ENV === 'production', // Disable sentry in localhost
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  debug: import.meta.env.VITE_NODE_ENV === 'development',
  tracesSampleRate: 1.0, // for performance monitoring
  profilesSampleRate: 1.0, // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ]
})
// =======================================================

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

root.render(<App />)
