import * as Sentry from '@sentry/react'
import appRoutes from 'app/routes'
import { RouterProvider } from 'react-router-dom'
import createLazyRouter from 'router/utils/createLazyRouter'

const router = createLazyRouter(appRoutes)

function App() {
  return <RouterProvider router={router} />
}

export default Sentry.withProfiler(App, {
  includeRender: true,
  includeUpdates: true
})
