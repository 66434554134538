import { AppPath } from 'app/paths'
import dashboardRoutes from 'dashboard/routes'
import loginRoutes from 'login/routes'
import resultRoutes from 'result/routes'
import { LazyRouteObject } from 'router/utils/types'

const appRoutes: LazyRouteObject[] = [
  {
    path: AppPath.BasePath,
    Component: () => import('app/routes/~'),
    children: [...loginRoutes, ...dashboardRoutes, ...resultRoutes]
  }
]

export default appRoutes
