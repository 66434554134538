import { DashboardPaths } from 'dashboard/paths'
import { LazyRouteObject } from 'router/utils/types'

const dashboardRoutes: LazyRouteObject[] = [
  {
    path: DashboardPaths.Dashboard,
    Component: () => import('dashboard/routes/dashboard')
  }
]

export default dashboardRoutes
