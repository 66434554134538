import { ResultPath } from 'result/paths'
import { LazyRouteObject } from 'router/utils/types'

const resultRoutes: LazyRouteObject[] = [
  {
    path: ResultPath.Result,
    Component: () => import('result/routes/result')
  }
]

export default resultRoutes
